<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="名称搜索"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'admin.AdvertPosition.add',
                addPerm: 'admin_AdvertPosition_add',
                editUrl: 'admin.AdvertPosition.edit',
                editPerm: 'admin_AdvertPosition_edit',
                delUrl: 'admin.AdvertPosition.delete',
                delPerm: 'admin_AdvertPosition_delete',
                listUrl: 'admin.AdvertPosition.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null
                },
                columns: [
                    {prop: "name", label: "名称", required: true},
                    {prop: "sort", label: "排序", type: "number", required: true},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
